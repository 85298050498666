<template>
    <styled-dialog
        :value="value"
        :width="666"
        @input="$emit('input', $event)">
        <template #heading>
            <div class="heading">
                <h2>
                    Share Account to Group
                </h2>
                <span
                    class="close"
                    @click="$emit('input', false)">
                    <icon
                        name="close"
                        size="20"
                        color="#8F9EA6" />
                </span>
            </div>
        </template>
        <div class="description mx-5 mb-4">
            <h2>Select the group you want to share this account with.</h2>
            <h3>This will give the group access to the account.</h3>
        </div>

        <div class="actions text-center mx-5 mb-5">
            <v-select
                v-model="group"
                :items="groups"
                :disabled="loading"
                :loading="loading"
                class="styled-field mb-5"
                item-value="key"
                item-text="name"
                return-object
                label="Select a group to share" />
            <a
                href="#"
                @click.prevent="$emit('input', false)">
                Cancel
            </a>
            <styled-button
                class="ml-5 small"
                :loading="loading"
                :disabled="buttonNotAvailable"
                @click="share">
                SHARE TO GROUP
            </styled-button>
            <div
                v-if="error"
                class="error-message">
                {{ error }}
            </div>
        </div>
    </styled-dialog>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import Icon from '@/components/globals/Icon';
import { mapState } from 'vuex';

export default {
    components: {
        StyledDialog,
        StyledButton,
        Icon
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        accounts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            error: '',
            group: {},
            groups: []
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
            currentUser: (state) => state.user.user
        }),
        buttonNotAvailable() {
            const userSelected = this.group.id ? true : false;
            return this.loading || !userSelected;
        }
    },
    watch: {
        value() {
            if (this.value === true) {
                this.getGroups();
            }
        }
    },
    methods: {
        async getGroups() {
            try {
                this.loading = true;
                const response = await this.$apiRepository.getAccountGroups();
                this.groups = response.data.data;
                if (this.groups.length) {
                   this.groups.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                }
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        async share() {
            try {
                this.loading = true;
                const shareData = {
                    agency_id: this.currentAgency.id,
                    dealer_ids: this.accounts,
                    group_ids: [this.group.id]
                };
                const response = await this.$apiRepository.addToGroup(shareData);
                const sharedResult = response.data.data[0].name ?? null;
                if (sharedResult) {
                    this.group = {};
                    this.$flash('The account has been successfully shared!', 'green');
                    this.$emit('input', false);
                    this.$emit('refresh');
                }
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.description {
    color: #6D797F;
    h2 {
        color: #6D797F;
        font-size: 23px;
    }
    h3 {
        color: #6D797F;
        font-size: 18px;
    }
}
.close {
    cursor: pointer;
}
.error-message {
    text-align: center;
    margin-top: 15px;
    color: $error-500;
}
button.small {
    height: 40px;
}
</style>
