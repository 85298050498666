<template>
    <styled-dialog
        :value="value"
        :width="666"
        @input="$emit('input', $event)">
        <template #heading>
            <div class="heading">
                <h2>
                    Create group
                </h2>
                <button
                    class="close"
                    @click="$emit('input', false)">
                    <icon
                        name="close"
                        size="20"
                        color="#8F9EA6" />
                </button>
            </div>
        </template>
        <div class="description mx-5 mb-4">
            Select the group type for the accounts you have chosen.
            Choose between an organization or a user for better organization.
        </div>
        <v-radio-group
            v-model="groupType"
            class="styled-radio mx-5 mb-4"
            row>
            <v-radio
                label="Share to organization"
                value="Organization"
                color="primary" />
            <v-radio
                label="Share to user"
                value="Personal"
                color="primary" />
        </v-radio-group>

        <div class="actions text-center mx-5">
            <v-text-field
                v-model="groupName"
                :error="duplicationError"
                :error-messages="errorMessages"
                class="styled-field mb-5"
                label="CREATE GROUP NAME" />
        </div>
        <div
            v-if="groupType === 'Personal'"
            class="description mx-5 mb-3">
            Select the group type for the accounts you have chosen.
            Choose between an organization or a user for better organization.
        </div>
        <div class="actions text-center mx-5 mb-5">
            <v-select
                v-if="groupType === 'Personal'"
                v-model="user"
                :items="users"
                :disabled="loading"
                class="styled-field mb-5"
                item-value="key"
                item-text="name"
                return-object
                label="Select User" />
            <button
                class="text-link"
                href="#"
                @click="$emit('input', false)">
                Cancel
            </button>
            <styled-button
                class="ml-5 small"
                :loading="loading"
                :disabled="buttonNotAvailable"
                @click="createGroup">
                CREATE GROUP
            </styled-button>
            <div
                v-if="error"
                class="error-message">
                {{ error }}
            </div>
        </div>
    </styled-dialog>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import Icon from '@/components/globals/Icon';
import { mapState } from 'vuex';

export default {
    components: {
        StyledDialog,
        StyledButton,
        Icon
    },
    props: {
        value: {
            type: Boolean,
            required: true
        },
        accounts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            groupType: 'Organization',
            groupName: '',
            loading: false,
            error: '',
            errorMessages: [],
            duplicationError: false,
            user: {},
            users: []
        };
    },
    watch: {
        groupName() {
            this.duplicationError = false;
            this.errorMessages = [];
        },
        value() {
            if (this.value === true) {
                this.getUsers();
            }
        }
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
            currentUser: (state) => state.user.user
        }),
        buttonNotAvailable() {
            if (this.groupType === 'Organization') {
                return this.loading || !this.groupName;
            } else {
                const userSelected = this.user.id ? true : false;
                return this.loading || !this.groupName || !userSelected;
            }
        }
    },
    methods: {
        async getUsers() {
            try {
                const response = await this.$apiRepository.getSpecificUsers(['super_admin', 'admin', 'agency_dashboard', 'performance_manager'], this.currentAgency.id);
                const usersSorted = response.data.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                this.users = usersSorted;
            } catch (error) {
                this.error = error;
            }
        },
        async createGroup() {
            try {
                this.loading = true;
                this.error = '';
                const userId = {
                    user_id: this.groupType === 'Personal' ? this.user.id : null
                };
                const groupData = {
                    name: this.groupName,
                    group_type: this.groupType,
                    agency_id: this.currentAgency.id,
                    created_by_user_id: this.currentUser.id,
                    dealer_ids: this.accounts,
                };
                if (this.groupType === 'Personal') {
                    Object.assign(groupData, userId);
                }
                const response = await this.$apiRepository.createAccountGroup(groupData);
                const createdGroup = response.data.data.name ?? null;
                if (createdGroup) {
                    this.groupName = '';
                    this.$flash('The group has been successfully created!', 'green');
                    this.$store.dispatch('getGroups', this.currentAgency.id);
                    this.$emit('input', false);
                    this.$emit('refresh');
                }
            } catch (error) {
                console.log(error);
                const exist = 'A group with this name already exist.';
                const messageExist = error.response?.data?.error?.messages[0]?.includes(exist) ?? false;
                if (messageExist) {
                    this.duplicationError = true;
                    this.errorMessages.push(exist);
                } else {
                    this.error = error;
                }
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.heading {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.description {
    color: #6D797F;
}
.close {
    cursor: pointer;
}
.error-message {
    text-align: center;
    margin-top: 15px;
    color: $error-500;
}
button.small {
    height: 40px;
}
</style>
