<template>
    <div class="accounts-custom-table">
        <create-group-modal
            v-model="createGroupModalOpen"
            :accounts="selectedItemsIds"
            @refresh="resetFilters(false)" />
        <share-modal
            v-model="shareModalOpen"
            :accounts="selectedItemsIds"
            @refresh="resetFilters(false)" />
        <styled-dialog v-model="confirmReactivateDialogActive">
            <template #heading>
                <h3>Are you sure you want to reactivate this account?</h3>
            </template>
            <p class="mb-5">
                Reactivating an account will require you to re-onboard the associated products. Most of the configuration will remain in place but pay close attention to any missing dependencies that need to be re-established.
            </p>
            <div class="text-xs-center">
                <styled-button
                    :loading="loadingReactivation"
                    @click="reactivateDealer(currentDealerId)">
                    YES, REACTIVATE
                </styled-button>
            </div>
            <v-btn
                color="error"
                flat
                @click="confirmReactivateDialogActive = false">
                cancel
            </v-btn>
        </styled-dialog>
        <styled-card
            hide-toggle
            class="mb-4">
            <template #action-buttons>
                <div
                    class="icon-button"
                    @click="$router.push({ name: 'account-new' })">
                    <icon
                        name="indicator-add"
                        size="15"
                        color="white" />
                    <span>ADD NEW ACCOUNT</span>
                </div>
                <div v-outside-click="hideTooltip">
                    <div
                        v-if="selectedItems.length"
                        class="accounts-selected"
                        @click="accountsTooltipActive = true">
                        {{ selectedItems.length }} Account<span v-if="selectedItems.length !== 1">s</span> selected
                        <accounts-tooltip
                            v-if="accountsTooltipActive"
                            :selected-items="selectedItems"
                            @select-item="selectItem"
                            @create="accountsTooltipActive = false; createGroupModalOpen = true;" />
                    </div>
                </div>
                <styled-tooltip
                    :max-width="220"
                    position="top">
                    <template
                        v-if="selectedItems.length === 0"
                        #content>
                        <div class="text-center">
                            Please select at least 1 account to create a group.
                        </div>
                    </template>
                    <div
                        v-if="permissionCreateAccountGroup"
                        class="create-button"
                        :class="{'disabled': selectedItems.length === 0}"
                        @click="createGroupModalOpen = true">
                        Create a Group
                    </div>
                </styled-tooltip>
                <styled-tooltip
                    :max-width="220"
                    position="top">
                    <template
                        v-if="selectedItems.length === 0"
                        #content>
                        <div class="text-center">
                            Please select at least 1 account to share with a group.
                        </div>
                    </template>
                    <div
                        class="create-button"
                        :class="{'disabled': selectedItems.length === 0}"
                        @click="shareModalOpen = true">
                        Share
                    </div>
                </styled-tooltip>
                <action-button
                    class="ml-5"
                    icon="download"
                    :disabled="dealersExportLoading || loadingDealers"
                    :loading="dealersExportLoading"
                    @click="exportCSV()">
                    Export CSV
                </action-button>
                <action-button
                    class="pr-3"
                    icon="refresh"
                    :disabled="dealersExportLoading || loadingDealers"
                    @click.prevent="resetFilters()">
                    Refresh
                </action-button>
            </template>
            <custom-table
                ref="accountsTable"
                name="accounts"
                :class="{'admin-view': userIsAdmin, 'non-admin-view': !userIsAdmin}"
                :items="accounts"
                :fields="fields"
                :loading="loadingDealers"
                :pagination="pagination"
                :rows-per-page="pagination.rowsPerPage"
                :total-items="pagination.meta.totalItems"
                :notification="notification"
                :notification-type="notificationType"
                :selected-filters="selectedFilters"
                :custom-filters="customFilters"
                min-width="1200px"
                backend-selection
                backend-filtering
                has-filters
                selectable
                hide-sort
                @select-item="selectItem"
                @update-pagination="updateAccounts"
                @update-filtering="updateFiltering"
                @search="onSearch">
                <template #header-slot>
                    <v-select
                        v-model="accountStatus"
                        :loading="loadingDealers"
                        :disabled="loadingDealers"
                        :items="status"
                        class="styled-field account-status" />
                    <div
                        v-if="selectedTags.length"
                        class="filters-wrapper">
                        <div class="filter-title">
                            Filtered by:
                        </div>
                        <div class="filter-applied">
                            <template v-if="selectedTags.length">
                                <div
                                    v-for="(tag, index) in selectedTags"
                                    :key="index"
                                    class="filter-single">
                                    <span>{{ tag }}</span>
                                    <div @click="clearTag(tag)">
                                        <icon
                                            name="close"
                                            color="#8f9ea6"
                                            size="10"
                                            @click="clearTag(tag)" />
                                    </div>
                                </div>
                            </template>
                            <div
                                class="filter-clear"
                                @click="clearAllSelections">
                                Clear all
                            </div>
                        </div>
                    </div>
                </template>
            </custom-table>
        </styled-card>
    </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { PRODUCTS } from '@/helpers/globals';
import ActionButton from '../globals/ActionButton.vue';
import Icon from '@/components/globals/Icon';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import CreateGroupModal from '@/components/account-management/CreateGroupModal';
import AccountsTooltip from '@/components/account-management/AccountsTooltip';
import ShareModal from '@/components/account-management/ShareModal';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledDialog from '../globals/StyledDialog.vue';
import StyledCard from '../globals/StyledCard.vue';
import StyledButton from '../globals/StyledButton';
import exportToCsv from '../../helpers/exportToCsv';
import { PERMISSION_CREATE_ACCOUNT_GROUP } from '@/helpers/globals';

export default {
    components: {
        ActionButton,
        Icon,
        CustomTable,
        CreateGroupModal,
        AccountsTooltip,
        ShareModal,
        StyledTooltip,
        StyledCard,
        StyledButton,
        StyledDialog
    },
    title: 'Accounts',
    data() {
        return {
            accounts: [],
            fields: [
                {
                    id: 'id',
                    header: 'ID',
                    value: 'id',
                    align: 'left',
                    width: '6%',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/account-management/AccountLink'),
                    props: {
                        type: 'id'
                    }
                },
                {
                    id: 'created_at',
                    header: 'Date Created',
                    value: 'created_at.date',
                    align: 'left',
                    width: '10%',
                    sortable: true,
                    type: 'date',
                },
                {
                    id: 'name',
                    header: 'Account',
                    value: 'name',
                    align: 'left',
                    width: '23%',
                    sortable: true,
                    type: 'component',
                    filter: 'search',
                    component: () => import('@/components/account-management/AccountLink'),
                    props: {
                        type: 'name'
                    }
                },
                {
                    id: 'account_groups.name',
                    header: 'Groups',
                    value: 'account_groups.name',
                    align: 'left',
                    width: '8%',
                    sortable: false,
                    type: 'component',
                    filter: 'search',
                    component: () => import('@/components/account-management/AccountGroups'),
                },
                {
                    id: 'platforms.display_name',
                    header: 'Channels',
                    value: 'platforms.display_name',
                    align: 'left',
                    width: '21%',
                    sortable: false,
                    type: 'component',
                    filter: 'multiple',
                    placeholder: 'All',
                    component: () => import('@/components/account-management/AccountChannels'),
                },
                {
                    id: 'products.display_name',
                    header: 'Products',
                    value: 'products.display_name',
                    align: 'left',
                    width: '10%',
                    sortable: false,
                    type: 'component',
                    filter: 'multiple',
                    placeholder: 'All',
                    component: () => import('@/components/account-management/AccountProducts'),
                },
                {
                    id: 'created_by_user',
                    header: 'Created By',
                    value: '',
                    align: 'left',
                    width: '10%',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/account-management/CreatedBy'),
                },
                {
                    id: 'status',
                    header: 'Status',
                    value: '',
                    align: 'left',
                    width: '7%',
                    sortable: false,
                    type: 'component',
                    component: () => import('@/components/account-management/AccountStatus'),
                    callback: (value) => {
                        this.confirmReactivateDialog(value.id);
                    }
                },
            ],
            organizationField: {
                id: 'agency.name',
                header: 'Organization',
                value: 'agency.name',
                align: 'left',
                width: '12%',
                sortable: true,
                filter: 'search',
                type: 'single',
            },
            selectedFilters: {},
            customFilters: [
                { 'platforms.display_name': ['Facebook', 'Tiktok', 'Snapchat', 'Pinterest', 'Google', 'Microsoft'] },
                { 'products.display_name': [] }
            ],
            filters: null,
            selectedTags: [],
            pagination: {
                rowsPerPage: 25,
                sortBy: 'created_at',
                descending: true,
                page: 1,
                meta: {
                    totalItems: 1,
                    current_page: 1,
                    last_page: 1,
                    to: 1,
                    from: 1,
                }
            },
            notification: '',
            notificationType: '',
            accountStatus: 'Active',
            status: ['Active', 'Inactive'],
            raw: [],
            search: '',
            accountsTooltipActive: true,
            selectedItems: [],
            createGroupModalOpen: false,
            shareModalOpen: false,
            loadingDealers: true,
            confirmReactivateDialogActive: false,
            currentDealerId: null,
            dealersExport: [],
            dealersExportLoading: false,
            loadingReactivation: false
        };
    },
    computed: {
        ...mapState({
            currentAgency: (state) => state.agency.currentAgency,
        }),
        ...mapGetters([
            'userIsAdmin'
        ]),
        permissionCreateAccountGroup() {
            return this.$store.getters.userHasPermission(PERMISSION_CREATE_ACCOUNT_GROUP);
        },
        selectedItemsIds() {
            return this.selectedItems.map((item) => {
                return item.id;
            });
        },
    },
    watch: {
        currentAgency() {
            this.getDealers();
        },
        accountStatus() {
            this.getDealers();
        }
    },
    mounted() {
        if (this.userIsAdmin) {
            this.fields.splice(4, 0, this.organizationField);
            // changing width of other columns to have some space for additional column
            this.fields[2].width = '15%'; // original width: 23%
            this.fields[5].width = '16%'; // original width: 21%
        }
        const productsFilters = [];
        PRODUCTS.forEach(product => { productsFilters.push(product.display_name) });
        this.customFilters.find(filter => filter['products.display_name'])['products.display_name'] = productsFilters;
    },
    activated() {
        this.getDealers();
    },
    methods: {
        clearAllSelections() {
            this.selectedFilters = {};
            this.filters = null;
            this.populateTags();
            this.getDealers();
        },
        clearTag(tag) {
            const currentTag = this.fields.find(field => field.header == tag);
            this.selectedFilters[currentTag.id] = [];
            this.populateTags();
            this.updateFiltering('', currentTag.id);
        },
        populateTags() {
            const tags = new Set();
            for (const filter in this.selectedFilters) {
                const currentFilter = this.fields.find(field => field.id == filter);
                if (this.selectedFilters[filter].length) {
                    tags.add(currentFilter.header);
                } else {
                    tags.delete(currentFilter.header);
                }
            }
            this.selectedTags = Array.from(tags);
        },
        updateFiltering(value, filter) {
            this.selectedFilters[filter] = value;
            const filters = [];
            Object.keys(this.selectedFilters).forEach(key => {
                if (this.selectedFilters[key] instanceof Array) {
                    if (this.selectedFilters[key].length) {
                        const arrayFilter = {
                            column: key,
                            operator: 'IN',
                            value: this.selectedFilters[key]
                        };
                        filters.push(arrayFilter);
                    }
                } else if (this.selectedFilters[key]) {
                    const stringFilter = {
                        column: key,
                        operator: 'like',
                        value: '%'+this.selectedFilters[key]+'%'
                    };
                    filters.push(stringFilter);
                }
            });
            this.filters = filters;
            this.populateTags();
            this.getDealers();
        },
        onSearch: debounce(async function(search) {
            this.pagination.page = 1;
            if (search) {
                this.search = search;
            } else {
                this.search = '';
            }
            await this.getDealers();
        }, 500),
        hideTooltip() {
            this.accountsTooltipActive = false;
        },
        selectItem(item, selection) {
            if (selection) {
                const itemObj = {
                    id: item.id,
                    name: item.name
                };
                this.selectedItems.push(itemObj);
            } else {
                const currentItemIndex = this.selectedItems.findIndex(selected => selected.id === item.id);
                this.selectedItems.splice(currentItemIndex, 1);
            }
            this.accounts.forEach(account => {
                if (account.id === item.id) {
                    account.selected = selection;
                }
            });
            // eslint-disable-next-line
            const updatedAccounts = structuredClone(this.accounts);
            this.accounts = updatedAccounts;
        },
        async updateAccounts(pagination) {
            Object.assign(this.pagination, pagination);
            await this.getDealers();
        },
        async getDealers() {
            try {
                this.loadingDealers = true;
                const response = await this.$apiRepository.getDealers(this.pagination, this.search, null, this.currentAgency.id, [this.accountStatus], this.filters);
                const accountsResponse = response.data.data.map((account) => {
                    const isSelected = this.selectedItems.filter(item => item.id === account.id);
                    account.selected = isSelected.length;
                    return account;
                });
                this.accounts = accountsResponse;
                this.raw = response.data;
                const newPagination = { ...this.pagination };
                newPagination.meta.totalItems = this.raw.meta.total;
                newPagination.meta.current_page = this.raw.meta.current_page;
                newPagination.meta.last_page = this.raw.meta.last_page;
                newPagination.meta.to = this.raw.meta.to;
                newPagination.meta.from = this.raw.meta.from;
                this.pagination = newPagination;
                this.loadingDealers = false;
            } catch (error) {
                this.$flash('Error retrieving accounts', 'red');
                this.loadingDealers = false;
                this.notification = error + '!';
                this.notificationType = 'error';
            }
        },
        resetFilters(clearSearch) {
            this.accounts = [];
            this.pagination = {
                rowsPerPage: 25,
                sortBy: 'created_at',
                descending: true,
                page: 1,
                meta: {
                    totalItems: 1,
                    current_page: 1,
                    last_page: 1,
                    to: 1,
                    from: 1,
                }
            };
            this.selectedItems = [];
            if (this.search && clearSearch) {
                this.$refs.accountsTable.clearSearch();
            } else {
                this.getDealers();
            }
        },
        getRDI(rdis, vdss=[]) {
            for(var i in rdis) {
                let rdi = rdis[i];
                if (rdi.vehicle_data_source_id == 65 && rdi.delegate_to_secondary_rdi == false) {
                    if (vdss.length == 0) {
                        return rdi.id_string;
                    } else {
                        for (var i2 in vdss) {
                            let vds = vdss[i2];
                            if (vds.id == rdi.vehicle_data_source_id &&
                                vds.id === 65) {
                                return 'BuyerBridge';
                            } else if (vds.id == rdi.vehicle_data_source_id) {
                                return vds.name;
                            }
                        }
                    }
                }
            }

            return false;
        },
        getSecondaryRDI(rdis, vdss=[]) {
            for(var i in rdis) {
                let rdi = rdis[i];
                if (rdi.vehicle_data_source_id != 65) {
                    if (vdss.length == 0) {
                        return rdi.id_string;
                    } else {
                        for (var i2 in vdss) {
                            let vds = vdss[i2];
                            if (vds.id == rdi.vehicle_data_source_id) {
                                return vds.name;
                            }
                        }
                    }
                }
            }

            return false;
        },
        async reactivateDealer(dealerId) {
            this.loadingReactivation = true;
            await this.$http.post(`/dealers/${dealerId}/activate`);
            this.loadingReactivation = false;
            this.confirmReactivateDialogActive = false;
            this.$flash('Account has been reactivated!', 'green');
            this.$router.push({
                name: 'dealer',
                params: {
                    dealer_id: dealerId
                },
            }, () => {
                // Update the dealers list after navigating away since
                // this view will remain active
                this.getDealers();
            });
        },
        confirmReactivateDialog(currentDealerId) {
            this.currentDealerId = currentDealerId;
            this.confirmReactivateDialogActive = true;
        },
        async getDealersData(page) {

            // Copy pagination object so we can manipulate it
            const pagination = { ...this.pagination };

            // Use the externally provided page if configured
            pagination.page = page || this.pagination.page;

            const response = await this.$apiRepository
                .getDealers(pagination, this.search, null, this.currentAgency.id, [this.accountStatus]);
            return response.data;
        },
        async exportCSV() {
            if (!this.accounts.length) {
                return;
            }

            try {
                const deleteFields = [
                    'plumbing_request_state',
                    'vehicle_data_last_processed',
                    'dealer_onboarding',
                    'lead_destinations',
                    'remote_dealer_identifiers',
                    'remote_files',
                    'comments',
                    'vehicle_data_sources',
                    'facebook_ad_account_annotations',
                    'dealer_upgrades',
                    'configuration'
                ];
                this.dealersExport = [];
                this.dealersExportLoading = true;

                let complete = false;
                let page = 1;
                let response;

                // Continue until complete
                while (!complete) {
                    response = await this.getDealersData(page);

                    this.dealersExport = this.dealersExport.concat(response.data);

                    // Load the require until the current page equals the last page
                    if (response.meta.current_page === response.meta.last_page) {
                        complete = true;
                    }

                    // Increment the page
                    page++;
                }

                // Prep for export
                this.dealersExport = this.dealersExport.map(dealer => {

                    dealer.created_at = this.$moment.utc(dealer.created_at.date).local().format('YYYY-MM-DD hh:mm:ss') || '';

                    dealer.deleted_at = (dealer.deleted_at && dealer.deleted_at.date) ? this.$moment.utc(dealer.deleted_at.date).local().format('YYYY-MM-DD hh:mm:ss') : '';

                    if (this.$userHasRole(['super_admin', 'admin'])) {
                        dealer.agency = (dealer.agency && dealer.agency.name) ?  dealer.agency.name : null;
                    }

                    if (dealer.contacts && dealer.contacts.data) {
                        let contacts = dealer.contacts.data.map(contact => {
                            return contact.first_name + ' ' + contact.last_name + ' ' + contact.email;
                        });
                        dealer.contacts = contacts.join('; ');
                    } else {
                        dealer.contacts = '';
                    }

                    if (dealer.products && dealer.products.data) {
                        let products = dealer.products.data.map(product => {
                            return product.display_name;
                        });
                        dealer.products = products.join('; ');
                    } else {
                        dealer.products = '';
                    }

                    dealer.site_provider = (dealer.site_provider && dealer.site_provider.name) ?  dealer.site_provider.name : null;

                    if (this.getRDI(dealer.remote_dealer_identifiers.data, dealer.vehicle_data_sources.data) !== false) {
                        dealer.rdi = this.getRDI(dealer.remote_dealer_identifiers.data, dealer.vehicle_data_sources.data);
                    } else {
                        dealer.rdi = this.getSecondaryRDI(dealer.remote_dealer_identifiers.data, dealer.vehicle_data_sources.data);
                    }
                    for (let deleteField in deleteFields) {
                        delete dealer[deleteFields[deleteField]];
                    }

                    // Change null values to empty strings
                    for (let field in dealer) {
                        if (dealer[field] === null) {
                            dealer[field] = '';
                        }
                    }

                    return dealer;
                });

                exportToCsv(`dealers_${new Date().getTime()}`, this.dealersExport);
            } catch (error) {
                this.$flash(error, 'red');
            } finally {
                this.dealersExportLoading = false;
            }
        }
    }
};
</script>

<style>
    .truncate-cell {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn.btn--floating:not(.btn--absolute):not(.btn--fixed) { z-index: 0 }
</style>
<style lang="scss">
.accounts-custom-table {
    .styled-card {
        position: relative;
        overflow: visible;
    }
    .account-status {
        width: 150px;
        display: inline-flex;
    }
    .accounts-selected {
        color: $blue-bg;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: underline;
        position: relative;
        cursor: pointer;
    }
    .create-button {
        padding: 5px 15px;
        background: $blue-btn;
        color: $white;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        text-transform: uppercase;
        &.disabled {
            background: #B8C2C7;
            cursor: default;
            pointer-events: none;
        }
    }
    .header-cell .filter {
        color: #6D797F !important;
    }
    .header-cell.active .filter{
        color: #050c11 !important;
    }
    .filters-wrapper {
        margin-top: -20px;
        display: inline-block;
        margin-left: 30px;
    }
    .filter-title{
        color: #00A4EB;
        margin-bottom: 5px;
    }
    .filter-applied{
        display: flex;
        align-items: center;
        flex-flow: row wrap;
    }
    .filter-single{
        display: flex;
        align-items: center;
        background: #D7F0FB;
        border-radius: 25px;
        padding: 5px 15px;
        margin-right: 15px;
        margin-bottom: 5px;
        span{
            margin-right: 15px;
        }
        div{
            display: flex;
            align-items: center;
            margin-top:2px;
            cursor: pointer;
        }
    }
    .filter-clear{
        color: #00A4EB;
        text-decoration: underline;
        cursor: pointer;
    }
    .admin-view .table-header > td:nth-of-type(7) .filter{
        max-width: 110px;
    }
    .admin-view .table-header > td:nth-of-type(8) .filter{
        max-width: 80px;
    }
    .non-admin-view .table-header > td:nth-of-type(4) .filter{
        max-width: 220px;
    }
    .non-admin-view .table-header > td:nth-of-type(6) .filter{
        max-width: 110px;
    }
    .non-admin-view .table-header > td:nth-of-type(7) .filter{
        max-width: 80px;
    }
}
</style>
