<template>
    <div class="a-tooltip">
        <div
            v-for="account in selectedItems"
            :key="account.id"
            class="a-tooltip__item">
            <div class="a-tooltip__holder">
                <div class="a-tooltip__desc">
                    {{ account.name }}
                </div>
            </div>
            <div
                class="a-tooltip__close"
                @click.stop="$emit('select-item', account, selection, account.parentGroup ?? null )">
                <icon
                    name="close"
                    color="white"
                    size="10" />
            </div>
        </div>
        <div
            v-if="permissionCreateAccountGroup"
            class="a-tooltip__complete"
            @click="$emit('create')">
            CREATE GROUP
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { PERMISSION_CREATE_ACCOUNT_GROUP } from '@/helpers/globals';

export default {
    components: {
        Icon,
    },
    props: {
        selectedItems: {
            type: Array,
            required: true
        },
        selection: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        permissionCreateAccountGroup() {
            return this.$store.getters.userHasPermission(PERMISSION_CREATE_ACCOUNT_GROUP);
        }
    }
};
</script>

<style lang="scss" scoped>
.a-tooltip {
    position: absolute;
    z-index: 4;
    background: white;
    padding: 15px;
    width: 428px;
    right:0;
    top: calc(100% + 25px);
    box-shadow: 0 0 6px 4px rgba(0,0,0,0.1);
    border-radius: 5px;
    cursor: default;
    &:after{
        content: '';
        position: absolute;
        width:20px;
        height: 20px;
        background: white;
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
        top: -10px;
        right: 15px;
        transform: rotate(45deg);
        z-index: 99;
    }
    &:before{
        content: '';
        position: absolute;
        width:60px;
        height:20px;
        background: white;
        top: 0;
        right: 0;
        z-index: 100;
    }
    &__item{
        background: #F7F8FC;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 5px 10px;
        color: #00A4EB;
        margin: 5px 0;
        text-transform: none;
        font-weight: 400;
    }
    &__holder{
        display: flex;
        align-items: center;
    }
    &__desc{
        overflow: hidden;
        max-width: 250px;
        white-space: nowrap;
    }
    &__close{
        display: flex;
        border-radius: 50%;
        background: #909FA8;
        padding: 5px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-right: 15px;
        cursor: pointer;
    }
    &__complete{
        display: table;
        margin: 0 auto;
        margin-top: 15px;
        text-align: center;
        color: white;
        font-weight: 700;
        cursor: pointer;
        font-size: 18px;
        padding:5px 25px;
        border-radius: 5px;
        background: #00A4EB;
        cursor: pointer;
    }
}
</style>